import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import {
  ActiveDomainRedirectorController,
  provideActiveDomainRedirectorController
} from '@tmf-ui-misc-controller/active-domain-redirector/active-domain-redirector.controller';
import { TemplateResult, html } from 'lit';

export class ActiveDomainRedirectorSmartElement extends TmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-active-domain-redirector-smart';

  private _controller: ActiveDomainRedirectorController = provideActiveDomainRedirectorController(this);

  public override connectedCallback(): void {
    super.connectedCallback();
  }

  public override disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  public override render(): TemplateResult {
    return html``;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-active-domain-redirector-smart': ActiveDomainRedirectorSmartElement;
  }
}
