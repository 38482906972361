import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { LOCATION } from '@bcflit-v1-configurators/tokens/app/token';
import {
  ActiveDomainRedirectorBloc,
  provideActiveDomainRedirectorBloc
} from '@tmf-logic-misc/bloc/active-domain-redirector/active-domain-redirector.bloc';
import { UnsubscribableController } from '@tmf-shared-classes/unsubscribable-controller';
import { transferRxNext } from '@tmf-shared-misc/rx-helpers';
import { DomainSettingsCountry } from '@tmf-shared-models/domain-settings';
import { ActivatedLang, provideActivatedLang } from '@tmf-shared-platform/activated-lang/activated-lang';
import {
  ExternalRedirectorService,
  externalRedirectorService
} from '@tmf-shared-platform/misc/external-redirector.service';
import { SharedSettings, provideSharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { ReactiveControllerHost } from 'lit';

export class ActiveDomainRedirectorController extends UnsubscribableController<ActiveDomainRedirectorController> {
  constructor(
    protected override _host: ReactiveControllerHost,
    protected override _bloc: ActiveDomainRedirectorBloc,
    private _sharedSettings: SharedSettings,
    private _activatedLang: ActivatedLang,
    private _location: Location,
    private _externalRedirectorService: ExternalRedirectorService
  ) {
    super(_host);
  }

  public hostConnected(): void {
    this._sub = this._activatedLang.currentLang$.subscribe(transferRxNext(this._bloc.currentLang$));
    this._sub = this._bloc
      .getDomainSettingsForCountryIp()
      .subscribe((domainSettings: DomainSettingsCountry | undefined) => {
        const baseDomainName: string = this._sharedSettings.env.baseDomainName;
        const currentHostname: string = this._location.hostname;
        if (!domainSettings) {
          return;
        }
        if (!currentHostname.includes(baseDomainName)) {
          return;
        }
        if (currentHostname !== domainSettings.activeDomain) {
          const currentUrlDetails: string = this._location.href.replace(this._location.origin, '');
          const newUrl: URL = new URL(currentUrlDetails, `https://${domainSettings.activeDomain}`);
          this._externalRedirectorService.redirectToExternalUrl(newUrl.toString(), false);
        }
      });
  }
}

export function provideActiveDomainRedirectorController(
  host: ReactiveControllerHost
): ActiveDomainRedirectorController {
  return new ActiveDomainRedirectorController(
    host,
    provideActiveDomainRedirectorBloc(),
    provideSharedSettings(),
    provideActivatedLang(),
    provideDiTokenValue(LOCATION),
    externalRedirectorService
  );
}
